import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AdminSettingsService {
  constructor(private http: HttpClient) {}

  getAllUser() {
    return this.http
      .get<any>(`${environment.api}/users`)
      .pipe(map((data) => data.data));
  }

  deleteUser(userId: string) {
    return this.http.delete<any>(`${environment.api}/users/${userId}`);
  }

  getAllRoles() {
    return this.http
      .get<any>(`${environment.api}/role/all`)
      .pipe(map((data) => data.data));
  }

  editUser(userId: string, userInfo: any) {
    delete userInfo.hal_id;
    delete userInfo.id;
    const body: FormData = new FormData();
    Object.keys(userInfo).forEach((key) => {
      body.append(key, userInfo[key]);
    });
    return this.http.put(`${environment.api}/users/${userId}`, body, {
      responseType: 'text',
    });
  }

  updateBrowserDeviceUser(userId: string, userBrowserDeviceInfo: string) {
    const body: FormData = new FormData();
    body.append('browser_device_infor', userBrowserDeviceInfo);
    return this.http.put(
      `${environment.api}/users/browser-device-infor/${userId}`,
      body,
      {
        responseType: 'text',
      }
    );
  }

  createUser(userInfo: any) {
    const body: FormData = new FormData();
    Object.keys(userInfo).forEach((key) => {
      body.append(key, userInfo[key]);
    });
    return this.http.post(`${environment.api}/users/create`, body);
  }

  getKeyWords() {
    return this.http
      .get<any>(`${environment.api}/keywords`)
      .pipe(map((data) => data.data));
  }

  createKeyWord(keyWord: any) {
    return this.http.post(`${environment.api}/keywords/create`, keyWord);
  }

  editKeyWord(keyWordId: string, keyWordData: any) {
    return this.http.put(
      `${environment.api}/keywords/${keyWordId}`,
      keyWordData
    );
  }

  deleteKeyWords(keyWords: string[]) {
    const body = new FormData();
    body.append('keywords', JSON.stringify(keyWords));

    return this.http.delete<any>(`${environment.api}/keywords/`, {
      body: body,
    });
  }

  getResponsibleParty() {
    return this.http
      .get<any>(`${environment.api}/npt-responsibilities`)
      .pipe(map((res: any) => res.data));
  }

  createResponsibilityParty(data: any) {
    const body: FormData = new FormData();
    Object.keys(data).forEach((key) => {
      body.append(key, data[key]);
    });
    return this.http.post(
      `${environment.api}/npt-responsibilities/create`,
      body
    );
  }

  editResponsibilityParty(nodeId: any, body: any) {
    const payload: FormData = new FormData();
    Object.keys(body).forEach((key) => {
      payload.append(key, body[key]);
    });
    return this.http.put(
      `${environment.api}/npt-responsibilities/${nodeId}`,
      payload
    );
  }

  deleteResponsibilityParty(type: string, nodeId: string) {
    return this.http.delete(
      `${environment.api}/npt-responsibilities/${type}/${nodeId}`
    );
  }

  deleteListResponsibilityParty(listNode: any) {
    const body: FormData = new FormData();
    Object.keys(listNode).forEach((key) => {
      body.append(key, JSON.stringify(listNode[key]));
    });
    return this.http.delete(`${environment.api}/npt-responsibilities/list`, {
      body: body,
    });
  }

  filterUsersGraph(keywords: string) {
    return this.http
      .get<any>(`${environment.api}/users/graph?characters=${keywords}`)
      .pipe(map((data) => data.data));
  }
}
